import React from "react";

const Clinic = () => {
  return (
    <section className="clinic-section clinic-top-section" id="section-61a816e">
      <div className="clinic-container clinic-column-gap-no">
        <div
          className="clinic-column clinic-col-100 clinic-top-column"
          id="column-c6d8550"
        >
          <div className="clinic-widget-wrap clinic-element-populated">
            <div
              className="clinic-widget clinic-widget-heading animated fadeInUp"
              id="widget-0005294"
              data-settings='{"_animation":"fadeInUp","_animation_tablet":"none","_animation_delay":100}'
            >
              <div className="clinic-widget-container">
                <h2 className="clinic-heading-title clinic-size-default">
                  Professional High Quality Video & Advertisement Shoot For
                  Clinic's & Hospitals
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clinic;
