import React from "react";

const VideoSection = () => {
  return (
    <div className="videosection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="video-flex">
              <div className="video">
                <iframe
                  width="330px"
                  height="200px"
                  src="https://www.youtube.com/embed/kkOuRJ69BRY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="video">
                <iframe
                  width="330px"
                  height="200px"
                  src="https://www.youtube.com/embed/kkOuRJ69BRY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="video">
                <iframe
                  width="330px"
                  height="200px"
                  src="https://www.youtube.com/embed/kkOuRJ69BRY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 d-flex">
            <div className="video" style={{ flex: 1, marginRight: "10px" }}>
              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/kkOuRJ69BRY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video" style={{ flex: 1 }}>
              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/kkOuRJ69BRY"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default VideoSection;
