import React from "react";
import { Button } from "react-bootstrap"; // Import Button from react-bootstrap
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Elements = () => {
  return (
    <div>
      {/* First Section */}
      <section className="service-section">
        {" "}
        {/* Aligns buttons horizontally */}
        <Button variant="primary" className="mx-2">
          Hospitals
        </Button>
        <Button variant="primary" className="mx-2">
          Path Labs
        </Button>
        <Button variant="primary" className="mx-2">
          Surgeons
        </Button>
        <Button variant="primary" className="mx-2">
          Physio Centers
        </Button>
        <Button variant="primary" className="mx-2">
          Nursing Homes
        </Button>
        <Button variant="primary" className="mx-2">
          Imaging Centers
        </Button>
      </section>

      {/* Second Section */}
      <section className="service-section2">
        {" "}
        {/* Aligns buttons horizontally */}
        <Button variant="primary" className="mx-2">
          Cardio, Brain & Sexology
        </Button>
        
        <Button variant="primary" className="mx-2">
          Healthcare Facility
        </Button>
        <Button variant="primary" className="mx-2">
          Aesthetic & Derma Clinics
        </Button>
      </section>

      {/* ======================= */}

      <div className="company-info">
        <span className="brands">
          Experienced Team Of Healthcare
          <br /> Marketing Experts That Leading
          <br />
          Healthcare Brands Trusts
        </span>
        <div className=" btn43">
          {/* <Button>Why to choose us</Button> */}
        </div>
      </div>
    </div>
  );
};

export default Elements;
