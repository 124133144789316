import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";

const ContLog = () => {
  // State to hold form data
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    monthly_invest: "",
    requirement: "",
  });

  // Handle input changes
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); 
    axios
      .post("http://localhost:8081/login", values)
      .then((res) => {
        console.log("Data inserted successfully!", res.data);
        alert("Registration successful!");
      })
      .catch((err) => {
        console.error("Error during registration", err);
        alert("Failed to register. Please try again.");
      });
  };

  return (
    <section className="contact-section pt-3 pb-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-7">
            <div className="mrk">
              <h6>Contact Us</h6>
              <h2>
                Partner with us for the Best Healthcare Marketing Experience
              </h2>
              <p>
                We’re happy to answer any questions you may have and help you
                determine which of our services best fit your needs.
              </p>

              <p>Call us at: +91-9971658013</p>

              <br />
            </div>
            <div className="bne">
              <h2>About Us:</h2>

              <br />
              <p>Our mission is to transform healthcare marketing by achieving exceptional performance, equipping brands to succeed in the digital space and and establish a lasting presence</p>

            </div>
          </div>
          <div className="col-lg-5">
            <div className="form-module">
              <div>
                <h2>Enquire Now</h2>
                <form onSubmit={handleSubmit} className="crtacc">
                  <h6>Full Name</h6>
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <h6>Email</h6>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <h6>Phone</h6>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <h5>What Will Be Your Monthly Investment Range</h5>
                  <input
                    type="text"
                    name="monthly_invest"
                    placeholder="Monthly Investment"
                    value={values.monthly_invest}
                    onChange={handleChange}
                  />

                  <h6>requirement</h6>
                  <input
                    type="text"
                    name="requirement"
                    placeholder="Requirement"
                    value={values.requirement}
                    onChange={handleChange}
                  />
                  <button type="submit">submit</button>
                </form>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContLog;
