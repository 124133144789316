
import { Nav } from "react-bootstrap";
import casestdyimage from "../../assets/images/case-study-4.png";
import Header from "../../Component/Header";

const CaseStudies = () => {
  return (
    <div>
       <div>
        <Header />
      </div>
      
      <div className="container">
        <div className="row casetest pt-5">

          <div className="col-md-6">
            <img src={casestdyimage} alt="case-study-1" />
            
          </div>
        <div className="col-md-6">
          
          <p>
          It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.
          </p>
        </div>
      </div>
      </div>

    </div>
  );
};
export default CaseStudies;
