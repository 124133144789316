import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import banner1 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiMarketo } from "react-icons/si";
import { GiTicket } from "react-icons/gi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdAddLocation } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { LiaPagerSolid } from "react-icons/lia";

const Seo = () => {
  return (
    <div>
      <div>
        <div>
          <Header />
        </div>
        <div className="pt1">
          <div className="container">
            <div className="row">
              <div className="col">
                <button className="pt2">
                  <Link to="/solutions" className="sols">
                    SOLUTIONS
                  </Link>
                </button>
                <h1>Medical SEO</h1>
                <p>
                  Achieve top rankings and attract
                  <br />
                  targeted traffic with Medical Sarthi's
                  <br />
                  expert medical SEO services.
                </p>
                <button className="pt3">
                  <Link to="/contact" className="frr">
                    Schedule Free Consultation
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h3>Medical SEO</h3>
                <p>
                  Achieve top rankings and attract targeted traffic with Medical
                  Sarthi's expert medical SEO services.
                  <br />
                  At Medical Sarthi, we employ a distinct and customized
                  strategy for medical SEO that differentiates us from the
                  competition. We recognize the unique challenges and needs of
                  the healthcare industry, and our skilled team employs advanced
                  techniques to enhance your website and content for search
                  engine optimization. 
                </p>
                <div className="coc">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <h4>Increased organic traffic</h4>
                        <p>
                          Through strategic optimization of your website and
                          content, Medical Sarthi's medical SEO services enhance
                          organic traffic to your online platforms. This
                          enhanced visibility results in a broader pool of
                          prospective patients and clients, ultimately yielding
                          a greater conversion rate and improved return on
                          investment (ROI).
                        </p>
                      </div>
                      <div className="col">
                        <h4>Enhanced Patient Engagement and Trust</h4>
                        <p>
                          A well-optimized website that offers valuable and
                          relevant information fosters trust with potential
                          patients. Medical Sarthi's medical SEO strategies
                          prioritize the creation of an engaging user
                          experience, enabling patients to easily access the
                          information they require. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vac">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div>
                          <h4>Enhanced search engine rankings</h4>
                          <p>
                            By focusing on relevant keywords and optimizing your
                            website structure, Medical Sarthi assists your
                            medical practice or healthcare brand in achieving
                            higher rankings in search engine results pages
                            (SERPs). 
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <h4>Long-term results and sustainable growth</h4>
                        <p>
                          Medical SEO is an investment that yields long-term
                          results. As your website builds authority and
                          credibility in search engines, it transforms into a
                          reliable source of patient leads.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <img className="dd" src={banner1} />
              </div>
            </div>
          </div>
        </div>

        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <img src={banner} />
              </div>

              <div className="col-8">
                <h3 className="ttms">
                  How We Achieve Top Rankings In Just 3 Months
                </h3>
                <div className="ttp">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <h4>Comprehensive Keyword Research</h4>
                        <p>
                          Medical Sarthi performs thorough keyword research to
                          identify high-value, relevant keywords for your
                          medical practice. By focusing on relevant keywords, we
                          enhance your content to achieve higher rankings in
                          search results and draw in targeted traffic.
                        </p>
                      </div>
                      <div className="col">
                        <h4>On-Page Optimization</h4>
                        <p>
                          Our team ensures that your website's structure, meta
                          tags, headings, and content are refined for search
                          engines. This involves optimizing page titles, meta
                          descriptions, URL structures, and integrating relevant
                          keywords to enhance your website's visibility and
                          search engine rankings.
                        </p>
                      </div>
                      <div className="col">
                        <h4>Quality Content Creation</h4>
                        <p>
                          Medical Sarthi prioritizes the development of
                          high-quality, informative, and engaging content that
                          connects with your target audience. By generating
                          valuable content that addresses patients' inquiries
                          and offers useful information, we enhance your
                          website's authority and attract organic traffic.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt6">
          <div className="container">
            <div className="row">
              <div className="col-7 ">
                <div className="ppat">
                  <br />
                  <br />
                  <h2>Technical SEO Enhancements</h2>
                  <p>
                    Our experts analyze and optimize the technical aspects of
                    your website, including site speed, mobile responsiveness,
                    URL structure, and XML sitemap creation. These optimizations
                    facilitate search engines in efficiently crawling and
                    indexing your website, resulting in improved rankings.
                  </p>
                </div>
                <div className="scnt">
                  <h5>Link Building and Off-Page SEO</h5>
                  <p>
                    Medical Sarthi utilizes effective link building strategies
                    to enhance your website's authority and credibility. By
                    obtaining high-quality backlinks from reputable sources, we
                    enhance your rankings and improve your online visibility.
                  </p>
                  <h5>Continuous Monitoring and Adjustments</h5>
                  <p>
                    We diligently monitor your website's performance, track
                    keyword rankings, and analyze user behavior to implement
                    data-driven adjustments. This continuous optimization
                    enables us to adjust to search engine algorithms, refine our
                    strategies, and sustain your top rankings over time.
                  </p>
                </div>
              </div>
              <div className="col-5">
                <div className="scnt">
                  <h5>
                    <SiGoogleads />
                    Google Ads
                  </h5>
                  <p>
                    Our meticulously designed campaigns effectively target and
                    engage distinct demographics, capturing the interest of
                    potential patients in their preferred online spaces. Through
                    engaging ad creatives and precise audience targeting, we
                    ensure a steady influx of high-quality leads, empowering
                    your healthcare practice to flourish in the digital
                    landscape. Unleash the potential of Facebook Ads and
                    transform your patient acquisition strategies today.
                  </p>
                  <h5>
                    <FaFacebook />
                    Facebook Ads
                  </h5>
                  <p>
                    Our expertly crafted campaigns precisely target and engage
                    specific demographics, capturing the attention of potential
                    patients where they spend the most time online. With
                    compelling ad creatives and strategic audience targeting, we
                    generate a consistent flow of high-quality leads, helping
                    your healthcare practice thrive in the digital realm.
                    Discover the power of Facebook Ads and revolutionize your
                    patient acquisition strategies today.
                  </p>
                  <h5>
                    <FaYoutube />
                    Youtube Ads
                  </h5>
                  <p>
                    Our engaging video campaigns on the leading video-sharing
                    platform connect your healthcare practice with a broad
                    audience. By utilizing visually appealing storytelling, we
                    enhance brand awareness and generate a consistent stream of
                    high-quality leads. Explore the benefits of YouTube Ads and
                    transform your patient acquisition strategies with Medical
                    Sarthi today.
                  </p>
                  <h5>
                    <FaWhatsapp />
                    WhatsApp Ads
                  </h5>
                  <p>
                    Our customized messaging campaigns on this widely used
                    communication platform facilitate direct and immediate
                    interaction with potential patients. By delivering tailored
                    messages, appointment reminders, and timely updates, we
                    foster meaningful relationships and ensure a continuous
                    influx of quality leads. Discover the effectiveness of
                    WhatsApp Ads and transform your patient acquisition
                    strategies with Medical Sarthi today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <InstaGal />
        </div>
        <div>
          <ContLog />
        </div>
        <div>
          <Footer />
        </div>
        <div>
          <Corect />
        </div>
      </div>
    </div>
  );
};

export default Seo;
