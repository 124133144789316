import React from "react";
import { Swiper, SwiperSlide } from "swiper/react"; 
import "swiper/css"; 
import "swiper/css/navigation"; 
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules"; 

const Sliders = () => {
  const images = [
    "assets/images/415531696999552.png",
    "assets/images/415531696999552.png",
    "assets/images/966271697786183.png",
    "assets/images/648031714050245.png",
    "assets/images/648031714050245.png",
    "assets/images/newmedical.jpg",
    "assets/images/newmedical.jpg",
    "assets/images/6-min-1.jpg",
    "assets/images/12_big-768x591.png",
    "assets/images/12_big-768x591.png",
   
   
    
  ];

  return (
    <div>
      {/* Swiper Slider (Left to Right) */}

      <Swiper
        modules={[Autoplay]} // Enable Autoplay
        spaceBetween={30}
        slidesPerView={7}
        autoplay={{
          delay: 2000,
          reverseDirection: false, // Move images from left to right
        }}
        loop={true} // Loop the slider
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img
              src={img}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Swiper Slider (Right to Left) */}

      <Swiper
        modules={[Autoplay]} // Enable Autoplay
        spaceBetween={30}
        slidesPerView={7}
        autoplay={{
          delay: 2000,
          reverseDirection: true, // Move images from right to left
        }}
        loop={true} // Loop the slider
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img
              src={img}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Sliders;
