import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Banner from "../../assets/medicalmarketing.webp";
import Banner1 from "../../assets/banner13.png";
const TabSection = () => {
  const [activeTab, setActiveTab] = useState("#tab1");

  // Handle URL hash to set the active tab when the page loads
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setActiveTab(hash);
    }
  }, []);

  // Update the URL hash when the tab changes
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    window.location.hash = tabId;
  };

  return (
    <div className="container">
      <div className="row">
        <h1>Industries we’ve excelled in</h1>
        <div className="tabbable">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                href="#tab1"
                className={`nav-link ${activeTab === "#tab1" ? "active" : ""}`}
                onClick={() => handleTabClick("#tab1")}
              >
                Doctors
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#tab2"
                className={`nav-link ${activeTab === "#tab2" ? "active" : ""}`}
                onClick={() => handleTabClick("#tab2")}
              >
                Clinics
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#tab3"
                className={`nav-link ${activeTab === "#tab3" ? "active" : ""}`}
                onClick={() => handleTabClick("#tab3")}
              >
                Hospitals
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${activeTab === "#tab1" ? "active" : ""}`}
              id="tab1"
            >
              <div className="healthcare-section-container">
                <div className="col-lg-7">
                  <div className="Heal-widget-wrap">
                    {/* <div className="Heal-widget Heal-widget-heading">
                      <div className="Heal-widget-container">
                        <div className="Heal-heading-title">
                          <button>Healthcare Sector</button>
                        </div>
                      </div>
                    </div> */}
                    <div className="Heal-widget Heal-widget-heading">
                      <div className="Heal-widget-container">
                        <h2 className="Heal-heading-title-1">
                          Healthcare Brands
                        </h2>
                      </div>
                    </div>
                    <div className="Heal-widget Heal-widget-text-editor">
                      <div className="Heal-widget-container">
                        <p>
                        Healthcare Medical Sarthi is India's largest and most innovative healthcare Marketing company. We help medical firms and practioners including Hospitals, Diagnostic Centers, Pharmaceutical Companies, Doctors, Nursing homes, Physicians, dentists, Cosmetic Companies, Ayurvedic Companies and other healthcare organizations in finding and engaging new clients or patients for their business.
                        </p>
                      </div>
                    </div>
                    <div className="Heal-widget Heal-widget-button">
                      <div className="Heal-widget-container">
                        <div className="Heal-button-wrapper">
                          <div className="Heal-button Heal-button-link" href="">
                            <span className="Heal-button-content-wrapper">
                              {/* <span className="Heal-button-text">
                                <button className="btn-get">Get Started</button>
                              </span> */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Heal-column Heal-top-column Heal-element Heal-background">
                  <div className="Heal-widget-wrap">
                    <div className="Heal-widget Heal-widget-spacer">
                      <div className="Heal-widget-container">
                        <div className="col-lg-5 w-100">
                          <div className="Heal-spacer-inner">
                            <img
                              loading="lazy"
                              decoding="async"
                              src={Banner}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane ${activeTab === "#tab2" ? "active" : ""}`}
              id="tab2"
            >
              <div className="healthcare-section-container">
                <div className="col-lg-7">
                  <div className="Heal-widget-wrap">
                    
                    <div className="Heal-widget Heal-widget-heading">
                      <div className="Heal-widget-container">
                        <h2 className="Heal-heading-title-1">Hospitals</h2>
                      </div>
                    </div>
                    <div className="Heal-widget Heal-widget-text-editor">
                      <div className="Heal-widget-container">
                        <p>
                        Healthcare SectorMedical Sarthi is India's largest and most innovative healthcare Marketing company. We help medical firms and practioners including Hospitals, Diagnostic Centers, Pharmaceutical Companies, Doctors, Nursing homes, Physicians, dentists, Cosmetic Companies, Ayurvedic Companies and other healthcare organizations in finding and engaging new clients or patients for their business.
                        </p>
                      </div>
                    </div>
                    <div className="Heal-widget Heal-widget-button">
                      <div className="Heal-widget-container">
                        <div className="Heal-button-wrapper">
                          <div className="Heal-button Heal-button-link" href="">
                            <span className="Heal-button-content-wrapper">
                              {/* <span className="Heal-button-text">
                                <button className="btn-get">Get Started</button>
                              </span> */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Heal-column Heal-top-column Heal-element Heal-background">
                  <div className="Heal-widget-wrap">
                    <div className="Heal-widget Heal-widget-spacer">
                      <div className="Heal-widget-container">
                      <div className="col-lg-5 w-100">
                          <div className="Heal-spacer-inner">
                            <img
                              loading="lazy"
                              decoding="async"
                              src={Banner}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane ${activeTab === "#tab3" ? "active" : ""}`}
              id="tab3"
            >
              <div className="healthcare-section-container">
                <div className="col-lg-7">
                  <div className="Heal-widget-wrap">
                    
                    <div className="Heal-widget Heal-widget-heading">
                      <div className="Heal-widget-container">
                        <h2 className="Heal-heading-title-1">
                          Healthcare Brands
                        </h2>
                      </div>
                    </div>
                    <div className="Heal-widget Heal-widget-text-editor">
                      <div className="Heal-widget-container">
                        <p>
                        Healthcare SectorMedical Sarthi is India's largest and most innovative healthcare Marketing company. We help medical firms and practioners including Hospitals, Diagnostic Centers, Pharmaceutical Companies, Doctors, Nursing homes, Physicians, dentists, Cosmetic Companies, Ayurvedic Companies and other healthcare organizations in finding and engaging new clients or patients for their business.
                        </p>
                      </div>
                    </div>
                    <div className="Heal-widget Heal-widget-button">
                      <div className="Heal-widget-container">
                        <div className="Heal-button-wrapper">
                          <div className="Heal-button Heal-button-link" href="">
                            <span className="Heal-button-content-wrapper">
                              {/* <span className="Heal-button-text">
                                <button className="btn-get">Get Started</button>
                              </span> */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Heal-column Heal-top-column Heal-element Heal-background">
                  <div className="Heal-widget-wrap">
                    <div className="Heal-widget Heal-widget-spacer">
                      <div className="Heal-widget-container">
                      <div className="col-lg-5 w-100">
                          <div className="Heal-spacer-inner">
                            <img
                              loading="lazy"
                              decoding="async"
                              src={Banner}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabSection;
