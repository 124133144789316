import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const items = [
  {
    name: "Bihar",
    img: "/assets/images/state_img_3.png",
  },
  {
    name: "Gujarat",
    img: "/assets/images/state_img_6.png",
  },
  {
    name: "Haryana",
    img: "/assets/images/state_img_7.png",
  },
  {
    name: "Karnataka",
    img: "/assets/images/state_img_11.png",
  },
  {
    name: "Madhya Pradesh",
    img: "/assets/images/state_img_13.png",
  },
  {
    name: "Maharashtra",
    img: "/assets/images/state_img_14.png",
  },
  {
    name: "Punjab",
    img: "/assets/images/state_img_20.png",
  },
  {
    name: "Rajsthan",
    img: "/assets/images/state_img_21.png",
  },
];
const Slick = () => {
  const settings = {
    infinite: true,
    slidesToShow: 5, // Shows 5 items in larger screens
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2, 
          slidesToScroll: 1, 
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1, 
        },
      },
    ],
  };

  return (
    <div className="multiple-items-slider">
      <h2>Serving Pan India Clients</h2>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="slick-slide">
            <img src={item.img} alt={item.name} style={{ width: "100%" }} />
            <p>{item.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slick;