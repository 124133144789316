// App.js
import React from "react";
// import "./App.css";
import { IoIosPeople } from "react-icons/io";
import { FaChartBar } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { FaSearchPlus } from "react-icons/fa";
import { FaAmbulance } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaRegAddressCard } from "react-icons/fa6";
import { Link } from "react-router-dom";
// import { #39e0e1 } from "@mui/material/colors";/

const ServicesSection = () => {
  return (
    <div>
      <section>
        <div className="services-section services-container">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <FaChartBar
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />
                <h2 className="services-cta__title">Patient Lead Generation</h2>
                <div className="services-cta__description">
                  Are you aiming to increase your patient leads? Medical Sarthi
                  is ready to assist! Attract targeted traffic to your clinic
                  with our cutting-edge solutions.
                </div>

                <div className="services-button " >
                  <Link to="/patient">Learn more</Link>
                </div>
              </div>
              <div className="col-md-3">
                <FaLaptopCode
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />
                <h2 className="services-cta__title">
                  Medical Website Development
                </h2>
                <div className="services-cta__description">
                  Whether you are a doctor, clinic, hospital, or healthcare
                  brand, we specialize in creating tailored websites
                </div>
                <div className="services-button " to="/">
                  <Link to="/medical">Learn more</Link>
                </div>
              </div>
              <div className="col-md-3">
                <FaSearchPlus
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />
                <h2 className="services-cta__title">Medical SEO</h2>
                <div className="services-cta__description">
                  Achieve top rankings and attract targeted traffic with Medical
                  Sarthi's expert medical SEO services.we employ a distinct
                </div>
                <div className="services-button " to="/">
                  <Link to="/seo">Learn more</Link>
                </div>
              </div>
              <div className="col-md-3">
                <FaAmbulance
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />
                <h2 className="services-cta__title">News Press Release</h2>
                <div className="services-cta__description">
                  Enhance your social media presence with Medical Sarthi's
                  expert management services. We specialize in healthcare services.
                </div>
                <div className="services-button " to="/">
                  <Link to="/media">Learn more</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="services-section services-container">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <IoIosPeople
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />
                <h2 className="services-cta__title">
                  Advertisement & Content Shoot
                </h2>
                <div className="services-cta__description">
                  Our experienced team produces compelling visuals and engaging
                  content that attract audiences, enhance your healthcare brand,
                  and create a lasting impression.
                </div>

                <div className="services-button " to="/">
                  <Link to="/shoot">Learn more</Link>
                </div>
              </div>
              <div className="col-md-3">
                <FaReact
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />

                <h2 className="services-cta__title">
                  Landing Page Development
                </h2>
                <div className="services-cta__description">
                  Our visually appealing and strategically optimized landing
                  pages are crafted to engage, inform, and drive action,
                  providing a seamless user experience.
                </div>
                <div className="services-button " to="/">
                  <Link to="/landing">Learn more</Link>
                </div>
              </div>
              <div className="col-md-3">
                <FaMobileAlt
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />

                <h2 className="services-cta__title">
                  Management of Online Reputation
                </h2>
                <div className="services-cta__description">
                  Our proactive approach to monitoring, managing, and improving
                  your digital presence guarantees a favorable and impactful
                  picture for your healthcare brand
                </div>
                <div className="services-button " to="/">
                  <Link to="/online">Learn more</Link>
                </div>
              </div>
              <div className="col-md-3">
                <FaRegAddressCard
                  style={{
                    width: "50px",
                    height: "50px",
                    fill: "#39e0e1",
                  }}
                />
                <h2 className="services-cta__title">Social Media Management</h2>
                <div className="services-cta__description">
                  We Maintain All Your Social Media Platform With Engaging
                  Medical Content and we employ a distinct and customized
                  strategy for social media SEO that ensures long-term success
                </div>
                <div className="services-button " to="/">
                  <Link to="/social">Learn more</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesSection;
