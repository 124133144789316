import React from "react";
import Header from "../../Component/Header";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import banner from "../../assets/cmp1.jpg";
import Textimg from "../../Component/Textimg";

const Company = () => {
  return (
    <section>
      <div>
        <Header />
      </div>
      

      <div>
        <Textimg />
      </div>

      <div>
        <InstaGal />
      </div>
      <div>
        <ContLog />
      </div>
      <div>
        <Footer />
      </div>
    </section>
  );
};

export default Company;
