import React from "react";
import { GoArrowRight } from "react-icons/go";
import Banner from "../../assets/banner9.png";
import Banner1 from "../../assets/banner10.png";

const SocialMediaSection = () => {
  // return (
  //   <section
  //     className="social-section"
  //     data-id="ae490fe"
  //     data-element_type="section"
  //   >
  //     <div className="social-container ">
  //       <div className="social-column">
  //         <div className="social-widget-wrap content-1">
  //           <div className="social-widget-line">
  //             <div className="social-widget-heading">
  //               <div className="social-heading-title">Daily Patient social</div>
  //               <div className="social-widget-heading">
  //                 <div className="social-heading-title">
  //                   Patient Lead Generation
  //                 </div>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="social-content-11">
  //             <div className="social-widget-image-box">
  //               <div className="social-image-box-wrapper d-flex">
  //                 <figure className="social-image-box-img">
  //                   <div href="https://Medical Sarthi.in/?p=2808" tabIndex="-1">
  //                     <img
  //                       loading="lazy"
  //                       decoding="async"
  //                       width="300"
  //                       height="200"
  //                       src={Banner}
  //                       alt=""
  //                     />
  //                   </div>

  //                   <div className="social-image-box-content">
  //                     <h5
  //                       className="social-image-box-title"
  //                       style={{ fontSize: "1.25em" }}
  //                     >
  //                       <div
  //                         href="https://Medical Sarthi.in/?p=2808"
  //                         style={{ color: "white" }}
  //                       >
  //                         Enagaging Content Creation For More Online Visibility
  //                       </div>
  //                     </h5>
  //                   </div>
  //                 </figure>
  //                 <div
  //                   className="background-container"
  //                   style={{
  //                     backgroundImage: `url(${Banner1})`,
  //                     backgroundSize: "cover",
  //                     backgroundPosition: "center",
  //                     backgroundRepeat: "no-repeat",
  //                     width: "39%",
  //                     marginLeft: "100px",
  //                   }}
  //                 ></div>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="social-widget-button">
  //             <div className="social-button-wrapper">
  //               <a className="social-button" href="https://wa.me/9971658013">
  //                 <div className="parent-container">
  //                   <span className="social-button-text">
  //                     Enquire Now <GoArrowRight />
  //                   </span>
  //                 </div>
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // );
};

export default SocialMediaSection;
