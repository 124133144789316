import React from "react";

const NumbersSection = () => {
  return (
    <section className="number-section number-top-section number-section-boxed number-section-height-default">
      <div className="number-container number-column-gap-no">
        <div className="number-column-div  number-col-100 number-top-column">
          <div className="number-widget-wrap number-element-populated">
            {/* First Heading */}
            <div className="number-widget-heading number-widget__width-auto">
              <div className="number-widget-container">
                
              </div>
            </div>

            {/* Second Heading */}
            <div
              className="number-widget-heading animated fadeInUp"
              style={{ animationDelay: "100ms" }}
            >
              {/* <div className="number-widget-container">
                <h2 className="number-heading-title-h2 number-size-default">
                  Numbers We Are Proud To Share
                </h2>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NumbersSection;
