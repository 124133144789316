import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import Banner2 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";

const Landing = () => {
  return (
    <div>
      <div>
        <div>
          <Header />
        </div>
        <div className="pt1">
          <div className="container">
            <div className="row">
              <div className="col">
                <button className="pt2">
                  <Link to="/solutions" className="sols">
                    SOLUTIONS
                  </Link>
                </button>
                <h1>Landing Page Development</h1>
                <p>
                  Our visually appealing and strategically optimized landing
                  pages are crafted to engage, inform, and drive action,
                  providing a seamless user experience that yields measurable
                  results for your healthcare brand.
                </p>
                <button className="pt3">
                  <Link to="/contact" className="frr">
                    Schedule Free Consultation
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h3>
                  Convert visitors into customers with Medial Sarthi's Dynamic
                  Landing Page Development.
                </h3>
                <p>
                  Medical Sarthi distinguishes itself in the field of landing
                  page development with a unique approach that differentiates
                  us. Our tailored designs are specifically crafted to meet the
                  unique needs of healthcare brands, ensuring a harmonious
                  integration of creativity and functionality.
                </p>
                <div className="coc">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <h4>Customized Designs</h4>
                        <p>
                          We recognize that each healthcare brand is distinct,
                          and we take pride in developing tailored landing page
                          designs that reflect your brand identity, values, and
                          target audience. 
                        </p>
                      </div>
                      <div className="col">
                        <h4>Strategic Content Placement</h4>
                        <p>
                          We thoughtfully position content elements, including
                          compelling headlines, engaging videos, and persuasive
                          calls to action, to attract visitors' attention and
                          motivate them to engage further with your healthcare
                          brand. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vac">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div>
                          <h4>Conversion-Focused Strategy</h4>
                          <p>
                            In contrast to standard landing page development, we
                            prioritize driving conversions and optimizing your
                            return on investment. Our team carefully optimizes
                            landing pages through persuasive copywriting.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <h4>Continuous Testing and Optimization</h4>
                        <p>
                          We believe in the effectiveness of data-driven
                          decision-making. That is why we consistently test and
                          optimize landing pages to improve their performance.
                          By examining user behavior, engagement metrics.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <img className="dd" src={Banner2} />
              </div>
            </div>
          </div>
        </div>

        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <img src={banner} />
              </div>

              <div className="col-8">
                <h3 className="ttms">
                  Double Your Conversion Rates On Your Website & Ads
                </h3>
                <p>
                  Our proven strategies and optimized techniques are intended to
                  double your conversion rates for both your website and
                  advertisements. Trust us to maximize your online presence,
                  effectively engaging visitors and converting them into
                  valuable leads and customers.
                </p>
                <div className="ttp">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <h4>Data-Driven Insights</h4>
                        <p>
                          Medical Sarthi utilizes data-driven insights to
                          pinpoint areas for improvement and enact strategic
                          changes that enhance your conversion rates. By
                          examining user behavior, engagement metrics, and
                          conversion funnels, we can make data-driven decisions
                          to achieve improved results.
                        </p>
                      </div>
                      <div className="col-6">
                        <h4>Targeted Audience Segmentation</h4>
                        <p>
                          We utilize sophisticated audience segmentation methods
                          to provide personalized and relevant experiences for
                          your target audience. By customizing your messaging,
                          offers, and user experiences for distinct segments, we
                          improve engagement and boost the chances of
                          conversions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="pt6">
          <div className="container">
            <div className="row">
              <div className="col-7 ">
                <div className="ppat">
                  <br />
                  <br />
                  <h1>Compelling Copywriting</h1>
                  <p>
                    Our skilled copywriters create persuasive and captivating
                    content that connects with your audience, clearly
                    articulating your value proposition and encouraging them to
                    take action. From impactful headlines to succinct
                    calls-to-action, we enhance every aspect of your copy for
                    optimal effectiveness.
                  </p>
                </div>
                <div className="scnt">
                  <h3>A/B Testing and Optimization</h3>
                  <p>
                    We perform comprehensive A/B testing on different components
                    of your website and advertisements, including headlines,
                    visuals, and call-to-action buttons. By testing various
                    versions and evaluating the outcomes, we consistently
                    enhance your assets to achieve higher conversion rates.
                  </p>
                  <h3>Seamless User Experience</h3>
                  <p>
                    We prioritize delivering a seamless user experience
                    throughout your website and advertisements. By optimizing
                    page load times, enhancing navigation, and ensuring mobile
                    responsiveness, we establish a user-friendly environment
                    that encourages visitor engagement and conversion.
                  </p>
                </div>
              </div>
              <div className="col-5">
                <div className="scnt">
                  <h3>
                    <SiGoogleads />
                    Google Ads
                  </h3>
                  <p>
                    Our targeted campaigns ensure your healthcare services are
                    visible to potential patients actively searching for
                    relevant solutions, driving high-quality leads. With our
                    expertise, we go beyond generic approaches, tailoring
                    strategies to your unique practice, audience, and goals,
                    ensuring maximum effectiveness. Experience the difference of
                    our data-driven approach and see how Google Ads can
                    revolutionize your patient acquisition efforts.
                  </p>
                  <h3>
                    <FaFacebook />
                    Facebook Ads
                  </h3>
                  <p>
                    Our expertly crafted campaigns precisely target and engage
                    specific demographics, capturing the attention of potential
                    patients where they spend the most time online. With
                    compelling ad creatives and strategic audience targeting, we
                    generate a consistent flow of high-quality leads, helping
                    your healthcare practice thrive in the digital realm.
                    Discover the power of Facebook Ads and revolutionize your
                    patient acquisition strategies today.
                  </p>
                  <h3>
                    <FaYoutube />
                    Youtube Ads
                  </h3>
                  <p>
                    Our captivating video campaigns on the world's largest
                    video-sharing platform connect your healthcare practice with
                    a vast audience. Engage potential patients through visually
                    compelling storytelling, drive brand awareness, and generate
                    a steady stream of high-quality leads. Discover the impact
                    of YouTube Ads and revolutionize your patient acquisition
                    strategies with Medical Sarthi today.
                  </p>
                  <h3>
                    <FaWhatsapp />
                    WhatsApp Ads
                  </h3>
                  <p>
                    Our personalized messaging campaigns on this popular
                    communication platform enable direct and instant
                    communication with potential patients. Engage, educate, and
                    convert with tailored messages, appointment reminders, and
                    timely updates, fostering meaningful connections and driving
                    a continuous flow of quality leads. Experience the
                    effectiveness of WhatsApp Ads and revolutionize your patient
                    acquisition strategies with Medical Sarthi today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <InstaGal />
        </div>
        <div>
          <ContLog />
        </div>
        <div>
          <Footer />
        </div>
        <div>
          <Corect />
        </div>
      </div>
    </div>
  );
};

export default Landing;
