import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import banner1 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";

const Shoot = () => {
  return (
    <div>
      <div>
        <div>
          <Header />
        </div>
        <div className="pt1">
          <div className="container">
            <div className="row">
              <div className="col">
                <button className="pt2">
                  <Link to="/solutions" className="sols">
                    SOLUTIONS
                  </Link>
                </button>
                <h1>Advertisement & Content Shoot</h1>
                <p>
                  Our experienced team produces compelling visuals and engaging
                  content that attract audiences, enhance your healthcare brand,
                  and create a lasting impression.
                </p>
                <button className="pt3">
                  <Link to="/contact" className="frr">
                    Schedule Free Consultation
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h5>
                  Engage audiences with Medical Sarthi's outstanding
                  advertisement and content shoot services
                </h5>
                <p>
                  Elevate your advertising and content creation with Medical
                  Sarthi’s location-based shoot and edit services. Our expert
                  team is prepared to capture exquisite visuals at your chosen
                  location and expertly edit them to produce impactful content.
                 
                </p>
                <div className="coc">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <h4>Location-Based Shoots</h4>
                        <p>
                          Bring your vision to life with Medical Sarthi's
                          location-based shoots. We strategically capture
                          engaging visuals at distinctive locations, producing
                          compelling content that connects with your target
                          audience and enhances your healthcare brand.
                        </p>
                      </div>
                      <div className="col">
                        <h4>Enhance Medical Content Creation</h4>
                        <p>
                          Our team specializes in 3D modeling, 2D animations,
                          professional voice-overs, and more, bringing your
                          medical concepts to life with superior visual and
                          auditory quality. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vac">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div>
                          <h4>1-day shoot for 1-month content</h4>
                          <p>
                            Experience the efficiency of Medical Sarthi's 1-day
                            shoot for 1-month content creation. Our expert team
                            maximizes productivity by capturing a diverse array
                            of engaging visuals in one day, providing you with a
                            month's worth of compelling content.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <h4>Advertisement shoots for brands</h4>
                        <p>
                          Our talented team creates captivating visuals and
                          compelling narratives that showcase your brand's
                          unique value proposition, producing impactful
                          advertisements that connect with your target audience.
                         
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <img className="dd" src={banner1} />
              </div>
            </div>
          </div>
        </div>

        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <img src={banner1} />
              </div>

              <div className="col-8">
                <h3 className="ttms">
                  Unleash the potential of professional content shoots with
                  Medical Sarthi
                </h3>
                <div className="ttp">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <h4>Professional Equipment</h4>
                        <p>
                          Our state-of-the-art tools, including drones, 4K
                          cameras, advanced lighting setups, and high-quality
                          audio recording, guarantee stunning visuals and
                          immersive storytelling that engage your audience and
                          enhance your healthcare brand.
                        </p>
                      </div>
                      <div className="col-6">
                        <h4>Creative Content Team</h4>
                        <p>
                          Our committed professionals bring extensive experience
                          and innovative perspectives, creating compelling
                          narratives and visuals that engage, inform, and
                          inspire.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        
        <div>
          <InstaGal />
        </div>
        <div>
          <ContLog />
        </div>
        <div>
          <Footer />
        </div>
        <div>
          <Corect />
        </div>
      </div>
    </div>
  );
};

export default Shoot;
