import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import { Link } from "react-router-dom";
import Slick from "../Slick";
import SampleVideo from "../../assets/videoslider.mp4"; // Import your video file

const Nav = () => {
  return (
    <div className="videobanner">
    <section className="container-fluid">
      {/* Banner Section */}
          <div className="banner-widget-wrap">
            <div className="banner-widget-video">
              <video
                src={SampleVideo}
                alt="Trusted By 125+ Doctors"
                className="banner-video"
                autoPlay
                loop
                muted
                controls 
              />
            </div>
          </div>

      <section>
        <Slick />
      </section>
      </section>
      </div>
  );
};

export default Nav;
