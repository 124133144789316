import React from "react";

const HealthcareSection = () => {
  return (
    <section className="Health-section Health-top-section Health-section-boxed Health-section-height-default">
      <div className="Health-container Health-column-gap-no">
        <div className="Health-column Health-col-100 Health-top-column">
          <div className="Health-widget-wrap Health-element-populated">
            <div className="Health-widget Health-widget-heading">
              <div className="Health-widget-container">
                <h2 className="Health-heading-title Health-size-default">
                  We Provide Healthcare Clients With End To End Solutions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HealthcareSection;
