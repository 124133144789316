import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import banner2 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiMarketo } from "react-icons/si";
import { GiTicket } from "react-icons/gi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdAddLocation } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { LiaPagerSolid } from "react-icons/lia";

const Medical = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="pt1">
        <div className="container">
          <div className="row">
            <div className="col">
              <button className="pt2">
                <Link to="/solutions" className="sols">
                  SOLUTIONS
                </Link>
              </button>
              <h1>Medical Website Development</h1>
              <p>
                Whether you are a doctor, clinic, hospital, or healthcare brand,
                we specialize in creating tailored websites that highlight your
                unique offerings and build trust with patients.
              </p>
              <button className="pt3">
                <Link to="/contact" className="frr">
                  schedule a free consultation
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="pt5">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <h4>
                Enhance your Healthcare Brand with Medical Sarthi's Medical
                Website Development
              </h4>
              <p>
                Medical Sarthi distinguishes itself in medical website
                development by providing a customized and comprehensive
                approach. We exceed standard templates by dedicating time to
                comprehend your distinct brand and goals. 
              </p>
              <div className="coc">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h5>Individual Doctor Website</h5>
                      <p>
                        Enhance your online presence as a physician with Medical
                        Sarthi's customized website development. Differentiate
                        yourself, establish trust, and demonstrate your
                        expertise with a tailored website crafted to attract and
                        engage prospective patients. 
                      </p>
                    </div>
                    <div className="col">
                      <h5>Pharmacy & Lab Website</h5>
                      <p>
                        Enhance your pharmacy or lab's online presence with
                        Medical Sarthi's bespoke website development.
                        Differentiate yourself, establish trust, and draw in
                        customers with a customized online platform designed
                        specifically for your pharmacy or lab. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="vac">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div>
                        <h5>Clinic Website</h5>
                        <p>
                          Enhance your clinic's online presence with
                          personalized website development from Medical Sarthi.
                          Differentiate yourself from the competition, establish
                          credibility, and draw patients in with a tailor-made
                          website that showcases your clinic's unique services
                          and values. 
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <h5>Medical Company Website</h5>
                      <p>
                        Enhance your medical company's online presence with
                        Medical Sarthi's expert website development.
                        Differentiate yourself in the industry, display your
                        innovative medical equipment and devices, and establish
                        credibility with a tailored website. Attract customers,
                        build trust company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img className="dd" src={banner2} />
            </div>
          </div>
        </div>
      </div>

      <div className="pt5">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <img src={banner} />
            </div>

            <div className="col-8">
              <h3 className="ttms">Powerful & Functional Web Development</h3>
              <div className="ttp">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <h4>Hospital Website Development</h4>
                      <p>
                        Our team provides powerful and functional websites
                        customized to meet the distinct needs of hospitals. From
                        intuitive navigation to comprehensive features, we
                        develop online platforms that improve patient
                        experience, highlight medical services, and increase
                        engagement.
                      </p>
                    </div>
                    <div className="col-6">
                      <h4>Healthcare eCommerce Website</h4>
                      <p>
                        Experience the capabilities of Medical Sarthi's
                        healthcare eCommerce website development services. Our
                        team provides strong and effective online platforms
                        tailored specifically for healthcare businesses. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="pt6">
        <div className="container">
          <div className="row">
            <div className="col-7 ">
              <div className="ppat">
                <br />
                <br />
                <h2>Features & Technology</h2>
                <p>
                  Experience Medical Sarthi’s medical website development with
                  cutting-edge features and technological advancements. Our
                  websites provide intuitive navigation, efficient appointment
                  scheduling, secure patient portals, and additional features to
                  improve user experience and enhance your online presence.
                </p>
              </div>
              <div className="scnt">
                <h4>Mobile First Development Approach</h4>
                <p>
                  Adopt a mobile-first development strategy with Medical Sarthi.
                  Our websites are designed to provide an optimal user
                  experience on mobile devices, ensuring seamless navigation and
                  engagement for patients on the go.
                </p>
                <h4>SEO Optimized Delivery</h4>
                <p>
                  Our expertly crafted websites are built with search engine
                  optimization in mind, assisting you in achieving higher search
                  rankings and attracting organic traffic to your healthcare
                  business.
                </p>
              </div>
            </div>
            <div className="col-5">
              <div className="scnt">
                <h4>Professionally Unique Designs</h4>
                <p>
                  We develop custom designs that reflect the identity and values
                  of your healthcare business, guaranteeing a visually striking
                  and memorable online presence that distinguishes you from the
                  competition.
                </p>
                <h4>WordPress & Custom Coded Websites</h4>
                <p>
                  Whether you favor the flexibility and user-friendly interface
                  of WordPress or require a fully customized solution, our
                  expert team delivers outstanding websites that align with your
                  specific requirements and goals. Experience the advantages of
                  both worlds with Medical Sarthi's versatile website
                  development solutions.
                </p>
                <h4>2-Week Delivery Challenge</h4>
                <p>
                  Our dedicated team is focused on delivering your medical
                  website within a swift two-week timeframe, enabling you to
                  establish your online presence promptly and efficiently.
                  Experience the speed and efficiency of Medical Sarthi's
                  website development process, enabling you to concentrate on
                  what is most important—your patients.
                </p>
                <h4>24/7 Support System</h4>
                <p>
                  Our committed team is available 24/7 to address any
                  website-related issues, offer technical support, and ensure
                  the seamless operation of your online platform. Trust Medical
                  Sarthi for reliable support whenever you require it,
                  guaranteeing uninterrupted performance and an exceptional user
                  experience for your patients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <InstaGal />
      </div>
      <div>
        <ContLog />
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <Corect />
      </div>
    </div>
  );
};

export default Medical;
