import React from "react";
import Elements from "../Elements";
import Sliders from "../Sliders";
import ImageSection from "../ImageSection";
import HealthcareSection from "../HealthcareSection";
import ServicesSection from "../ServicesSection";
import NumbersSection from "../NumbersSection";
import EnquiriesSection from "../EnquiriesSection";
import SocialMediaSection from "../SocialMediaSection";
import ImageScroll from "../ImageScroll";
import Clinic from "../Clinic";
import VideoSection from "../VideoSection";
import Section from "../Section";
import TabSection from "../TabSection";
import SingleVideo from "../SingleVideo";
import InstaGal from "../InstaGal";
import ContLog from "../ContLog";
import Footer from "../Footer";
import Corect from "../Corect";
import Nav from "./../Nav/index";
import Header from "../Header";

const Navbar = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <Nav />
      </div>

      <div>
        <Elements />
      </div>
      <div>
        <Sliders />
      </div>
      <div>
        <ImageSection />
      </div>
      <div>
        <HealthcareSection />
      </div>
      <div>
        <ServicesSection />
      </div>

      <div>
        <NumbersSection />
      </div>

      {/* <div>
        <EnquiriesSection />
      </div> */}

      <div>
        <SocialMediaSection />
      </div>
      {/* <div>
        <ImageScroll />
      </div> */}
      <div>
        <Clinic />
      </div>
      <div>
        <VideoSection />
      </div>
      <div>
        <Section />
      </div>
      <div>
        <TabSection />
      </div>
      {/* <div>
        <SingleVideo />
      </div> */}
      <div>
        <InstaGal />
      </div>
      <div>
        <ContLog />
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <Corect />
      </div>
    </div>
  );
};

export default Navbar;
