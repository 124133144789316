import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import banner1 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiMarketo } from "react-icons/si";
import { GiTicket } from "react-icons/gi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdAddLocation } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { LiaPagerSolid } from "react-icons/lia";

const Reputation = () => {
  return (
    <div>
      <div>
        <div>
          <Header />
        </div>
        <div className="pt1">
          <div className="container">
            <div className="row">
              <div className="col">
                <button className="pt2">
                  <Link to="/solutions" className="sols">
                    SOLUTIONS
                  </Link>
                </button>
                <h1> Management of Online Reputation</h1>
                <p>
                  Our proactive approach to monitoring, managing, and improving
                  your digital presence guarantees a favorable and impactful
                  picture for your healthcare brand, cultivating credibility and
                  trust within your intended audience.
                </p>
                <button className="pt3">
                  <Link to="/contact" className="frr">
                    Make an Appointment for a Free Consultation
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h5>
                  Restore Your Credibility: You Can Trust Medical Sarthi to
                  Handle Your Online Reputation Expertly
                </h5>
                <p>
                  It is imperative for clinics, hospitals, and physicians to
                  uphold a favorable online reputation in the current digital
                  environment. When it comes to offering outstanding online
                  reputation management services specifically designed for the
                  healthcare sector, Medical Sarthi goes above and beyond.
                 
                </p>
                <div className="coc">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <h4>Google Reviews</h4>
                        <p>
                          Good Google reviews not only improve your internet
                          reputation but also have an impact on the
                          decision-making process of prospective patients.
                        </p>
                      </div>
                      <div className="col">
                        <h4>Sites for Reviews of Healthcare</h4>
                        <p>
                          Maintaining a solid reputation with patients looking
                          for particular healthcare services involves keeping an
                          eye on and responding to reviews on specialist review
                          sites like Healthgrades and RateMDs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vac">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div>
                          <h4>Platforms for Social Media</h4>
                          <p>
                            Taking care of and replying to messages, reviews,
                            and comments on social media sites like Facebook and
                            Twitter promotes patient engagement and builds
                            trust.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <h4>Internet-Based Listings</h4>
                        <p>
                          Keeping your listings current and correct on websites
                          like Yelp and the Yellow Pages increases your patient
                          accessibility and trustworthiness.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <img className="dd" src={banner1} />
              </div>
            </div>
          </div>
        </div>

        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <img src={banner} />
              </div>

              <div className="col-8">
                <h3 className="ttms">
                  A proficient strategy for managing your Online Reputation
                </h3>
                <p>
                  Medical Sarthi guarantees that the reputation of your medical
                  facility, physician, or clinic is strengthened, safeguarded,
                  and utilized to promote patient confidence and commercial
                  expansion.
                </p>
                <div className="ttp">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <h4>Customized Method</h4>
                        <p>
                          Medical Sarthi recognizes that the healthcare industry
                          has certain considerations, thus they take a
                          customized approach to online reputation management.
                          We know how important it is to protect patient privacy
                          and handle sensitive medical data, therefore we make
                          sure our tactics follow industry best practices.
                        </p>
                      </div>
                      <div className="col-6">
                        <h4>Preemptive Observation</h4>
                        <p>
                          We actively monitor social media, review sites,
                          directories, and other internet platforms to quickly
                          detect and resolve any unfavorable comments or false
                          information. We can quickly protect and improve your
                          online reputation if we remain watchful.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="pt6">
          <div className="container">
            <div className="row">
              <div className="col-7 ">
                <div className="ppat">
                  <br />
                  <br />
                  <h3>Strategic Cooperation</h3>
                  <p>
                    Our group strategically interacts with patients and virtual
                    communities. We handle reviews and comments with
                    professionalism and empathy, demonstrating your dedication
                    to patient care and answering any questions or concerns.
                    Positive interaction cultivates loyalty and trust in both
                    current and prospective patients.
                  </p>
                </div>
                <div className="scnt">
                  <h4>Review Generation:</h4>
                  <p>
                    Medical Sarthi employs techniques to elicit favorable
                    evaluations from contented clients. In order to attract new
                    patients and establish a solid online reputation, we invite
                    our patients to share their experiences and opinions.
                    Constant Enhancement We use data-driven insights to better
                    our strategy because we believe in continual progress. We
                    may modify and improve our strategy to make sure your online
                    reputation stays powerful and influential by examining
                    trends, keeping an eye on sentiment, and watching online
                    reputation measurements.
                  </p>
                  <h4>Field Tech Support</h4>
                  <p>
                    We provide reasonably priced IT solutions that lower
                    expenses and boost revenue.
                  </p>
                </div>
              </div>
              <div className="col-5">
                <div className="scnt">
                  <h4>
                    <SiGoogleads />
                    Google Ads
                  </h4>
                  <p>
                    Our targeted campaigns ensure your healthcare services are
                    visible to potential patients actively searching for
                    relevant solutions, driving high-quality leads. With our
                    expertise, we go beyond generic approaches, tailoring
                    strategies to your unique practice, audience, and goals,
                    ensuring maximum effectiveness. Experience the difference of
                    our data-driven approach and see how Google Ads can
                    revolutionize your patient acquisition efforts.
                  </p>
                  <h4>
                    <FaFacebook />
                    Facebook Ads
                  </h4>
                  <p>
                    Our expertly crafted campaigns precisely target and engage
                    specific demographics, capturing the attention of potential
                    patients where they spend the most time online. With
                    compelling ad creatives and strategic audience targeting, we
                    generate a consistent flow of high-quality leads, helping
                    your healthcare practice thrive in the digital realm.
                    Discover the power of Facebook Ads and revolutionize your
                    patient acquisition strategies today.
                  </p>
                  <h4>
                    <FaYoutube />
                    Youtube Ads
                  </h4>
                  <p>
                    Our captivating video campaigns on the world's largest
                    video-sharing platform connect your healthcare practice with
                    a vast audience. Engage potential patients through visually
                    compelling storytelling, drive brand awareness, and generate
                    a steady stream of high-quality leads. Discover the impact
                    of YouTube Ads and revolutionize your patient acquisition
                    strategies with Medical Sarthi today.
                  </p>
                  <h4>
                    <FaWhatsapp />
                    WhatsApp Ads
                  </h4>
                  <p>
                    Our personalized messaging campaigns on this popular
                    communication platform enable direct and instant
                    communication with potential patients. Engage, educate, and
                    convert with tailored messages, appointment reminders, and
                    timely updates, fostering meaningful connections and driving
                    a continuous flow of quality leads. Experience the
                    effectiveness of WhatsApp Ads and revolutionize your patient
                    acquisition strategies with Medical Sarthi today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <InstaGal />
        </div>
        <div>
          <ContLog />
        </div>
        <div>
          <Footer />
        </div>
        <div>
          <Corect />
        </div>
      </div>
    </div>
  );
};

export default Reputation;
