import React from "react";
import Banner from "../../assets/bann14.jpg";
import Banner1 from "../../assets/bann16.jpg";
import Banner2 from "../../assets/bban15.jpg";
import Banner3 from "../../assets/banner18.jpg";
import Banner4 from "../../assets/bann17.jpg";

const InstaGal = () => {
  return (
    <div>
      <section className="Gal-content">
        <div className="context-gal pt-4">Our Instagram Gallery</div>
      </section>
      <section className="Gal-container">
        <div className="Gal">
          <img src={Banner} />
        </div>
        <div className="Gal">
          <img src={Banner1} />
        </div>
        <div className="Gal">
          <img src={Banner2} />
        </div>
        <div className="Gal">
          <img src={Banner3} />
        </div>
        <div className="Gal">
          <img src={Banner4} />
        </div>
      </section>
    </div>
  );
};

export default InstaGal;
