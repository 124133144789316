import React from "react";

const Section = () => {
  const imageData = [
    {
      link: "http://www.instagram.com",
      imgSrc:
        "assets/images/pdf1-1.png",
      alt: "oraluxe_official",
    },
    {
      link: "http://www.instagram.com",
      imgSrc:
        "assets/images/pdf1-1.png",
      alt: "oraluxe_official",
    },
    {
      link: "http://www.instagram.com",
      imgSrc:
        "assets/images/pdf1-1.png",
      alt: "oraluxe_official",
    },
  ];
  const imageData_1 = [
    {
      link: "http://www.instagram.com",
      imgSrc:
        "assets/images/pdf1-1.png",
      alt: "manav_hospital",
    },
    {
      link: "http://www.instagram.com/",
      imgSrc:
        "assets/images/pdf1-1.png",
      alt: "aestheticswithrg",
    },
    {
      link: "http://www.instagram.com/drkunalaneja/",
      imgSrc:
        "assets/images/pdf1-1.png",
      alt: "drkunalaneja",
    },
  ];
  return (
    <div>
      <section className="sec-section">
        <div className="sec-container">
          <div className="sec-column">
            <div className="sec-widget-wrap">
              <div className="sec-widget sec-widget-heading fadeInUp">
                <div className="sec-widget-container">
                  <h2 className="sec-heading-title">
                    Recent Social Media Work Of Our Clients
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =============part-1=============== */}
      <div className="sec-1-section">
        <div className="sec-1-container">
          {imageData.map((data, index) => (
            <div key={index} className="sec-1-column">
              <div className="sec-1-widget-wrap">
                <div className="premium-image-scroll">
                  <div className="premium-image-scroll-section">
                    <div className="premium-image-scroll-container">
                      <div className="premium-image-scroll-vertical">
                        <a
                          className="premium-image-scroll-link"
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={data.imgSrc}
                            alt={data.alt}
                            className="premium-image-scroll-img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* =================part-3=================== */}
      <div className="sec-1-section">
        <div className="sec-1-container">
          {imageData_1.map((data, index) => (
            <div key={index} className="sec-1-column">
              <div className="sec-1-widget-wrap">
                <div className="premium-image-scroll">
                  <div className="premium-image-scroll-section">
                    <div className="premium-image-scroll-container">
                      <div className="premium-image-scroll-vertical">
                        <a
                          className="premium-image-scroll-link"
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={data.imgSrc}
                            alt={data.alt}
                            className="premium-image-scroll-img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* ================================== */}
    </div>
  );
};

export default Section;
