import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import banner2 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiMarketo } from "react-icons/si";
import { GiTicket } from "react-icons/gi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdAddLocation } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { LiaPagerSolid } from "react-icons/lia";

const Patient = () => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="pt1">
        <div className="container">
          <div className="row">
            <div className="col">
              <button className="pt2">
                <Link to="/solutions" className="sols">
                  SOLUTIONS
                </Link>
              </button>
              <h1>Patient Lead Generation</h1>
              <p>
                Are you aiming to increase your patient leads?
                <br />
                Medical Sarthi is ready to assist! Attract targeted traffic to
                your clinic with our cutting-edge solutions.
                <br />
                Schedule a Free Consultation Looking
              </p>
              <button className="pt3">
                <Link to="/contact" className="frr">
                  Schedule Free Consultation
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="pt5">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <h3>Patient Lead Generation through Google Ads & Meta Ads </h3>
              <p>
                At Medical Sarthi, we excel in generating patient inquiries
                <br />
                daily by strategically leveraging Google Ads and Facebook Ads,
                <br />
                utilizing a variety of campaign types and ad formats. Here’s how
                we do it:
              </p>
              <div className="coc">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h4>
                        <IoCall /> Call-Only Campaigns
                      </h4>
                      <p>
                        We develop focused call-only campaigns that prominently
                        display your contact number, making it easy for
                        potential patients to connect with your practice through
                        a simple click-to-call feature.
                      </p>
                    </div>
                    <div className="col">
                      <h4>
                        <MdAddLocation /> Location Campaigns
                      </h4>
                      <p>
                        Utilizing location-based targeting, we ensure your ads
                        reach individuals in specific geographic areas,
                        enhancing ad relevance and increasing the likelihood of
                        attracting local patients actively seeking healthcare
                        services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="vac">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div>
                        <h4>
                          <FaVideo /> Video Ads Campaigns
                        </h4>
                        <p>
                          Our engaging video ads provide a powerful medium to
                          showcase your healthcare services. By employing
                          captivating narratives and informative visuals, we
                          effectively capture the attention of your target
                          audience, motivating them to take action and
                          generating a consistent flow of patient inquiries.
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <h4>
                        <LiaPagerSolid />
                        Landing Page Campaigns
                      </h4>
                      <p>
                        Our expertly crafted landing pages act as dedicated
                        destinations for your ads. These pages are optimized to
                        deliver relevant and valuable information to potential
                        patients, encouraging them to take the desired actions,
                        such as scheduling an appointment, requesting a
                        consultation, or seeking additional information.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <img className="dd" src={banner2} />
            </div>
          </div>
        </div>
      </div>

      <div className="pt5">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <img src={banner} />
            </div>

            <div className="col-8">
              <h3 className="ttms">
                Tailored Marketing Strategies To Reach Wider
              </h3>
              <div className="ttp">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <h4>
                        <FaWhatsapp /> WhatsApp Marketing
                      </h4>
                      <p>
                        We leverage WhatsApp, a widely popular messaging
                        platform, to establish direct communication channels
                        with potential patients. Through targeted campaigns, we
                        send personalized messages, appointment reminders,
                        health tips, and updates, fostering a more personalized
                        and interactive experience. This real-time communication
                        enhances patient engagement, builds trust, and increases
                        the likelihood of conversions.
                      </p>
                    </div>
                    <div className="col-6">
                      <h4>
                        <MdOutlineAlternateEmail />
                        Email Marketing
                      </h4>
                      <p>
                        While WhatsApp Marketing focuses on instant messaging,
                        email marketing utilizes email as the primary
                        communication channel. Email campaigns involve sending
                        tailored messages, newsletters, promotions, and
                        healthcare-related content directly to potential
                        patients' email inboxes. Email marketing is effective
                        for reaching a larger audience, delivering more detailed
                        content, and nurturing long-term patient relationships.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="pt6">
        <div className="container">
          <div className="row">
            <div className="col-7 ">
              <div className="ppat">
                <br />
                <br />
                <h1>
                  Powerful Platforms and
                  <br /> Tools
                </h1>
                <p>
                  Unlock the potential of patient lead generation with our
                  powerful arsenal of platforms and tools. By strategically
                  leveraging cutting-edge technologies and proven techniques, we
                  drive exceptional results, connecting healthcare providers
                  with the right patients and propelling practices to new
                  heights of success.
                </p>
              </div>
              <div className="scnt">
                <h1>
                  <SiMarketo />
                  Influencer Marketing
                </h1>
                <p>
                  Our targeted influencer marketing initiatives harness the
                  influence and credibility of key figures in the healthcare
                  sector to promote your practice and services. By collaborating
                  with trusted voices, we create a strong connection with your
                  target audience, enhancing your outreach and engagement.
                  Discover the power of influencer marketing and reform your
                  patient acquisition strategies with Medical Sarthi today.
                </p>
                <h1>
                  <GiTicket />
                  High Ticket Funnels
                </h1>
                <p>
                  Our expertly crafted funnels are designed to attract and
                  nurture qualified leads, driving conversions for high-value
                  healthcare services. With strategic optimization, compelling
                  content, and targeted campaigns, we guide potential patients
                  through a seamless journey, maximizing engagement and
                  generating premium leads. Experience the effectiveness of
                  high-ticket funnels and revolutionize your patient acquisition
                  strategies with Medical Sarthi today.
                </p>
              </div>
            </div>
            <div className="col-5">
              <div className="scnt">
                <h1>
                  <SiGoogleads />
                  Google Ads
                </h1>
                <p>
                  Our meticulously designed campaigns effectively target and
                  engage distinct demographics, capturing the interest of
                  potential patients in their preferred online spaces. Through
                  engaging ad creatives and precise audience targeting, we
                  ensure a steady influx of high-quality leads, empowering your
                  healthcare practice to flourish in the digital landscape.
                  Unleash the potential of Facebook Ads and transform your
                  patient acquisition strategies today.
                </p>
                <h1>
                  <FaFacebook />
                  Facebook Ads
                </h1>
                <p>
                  Our expertly crafted campaigns precisely target and engage
                  specific demographics, capturing the attention of potential
                  patients where they spend the most time online. With
                  compelling ad creatives and strategic audience targeting, we
                  generate a consistent flow of high-quality leads, helping your
                  healthcare practice thrive in the digital realm. Discover the
                  power of Facebook Ads and revolutionize your patient
                  acquisition strategies today.
                </p>
                <h1>
                  <FaYoutube />
                  YouTube Ads
                </h1>
                <p>
                  Our engaging video campaigns on the leading video-sharing
                  platform connect your healthcare practice with a broad
                  audience. By utilizing visually appealing storytelling, we
                  enhance brand awareness and generate a consistent stream of
                  high-quality leads. Explore the benefits of YouTube Ads and
                  transform your patient acquisition strategies with Medical
                  Sarthi today.
                </p>
                <h1>
                  <FaWhatsapp />
                  WhatsApp Ads
                </h1>
                <p>
                  Our customized messaging campaigns on this widely used
                  communication platform facilitate direct and immediate
                  interaction with potential patients. By delivering tailored
                  messages, appointment reminders, and timely updates, we foster
                  meaningful relationships and ensure a continuous influx of
                  quality leads. Discover the effectiveness of WhatsApp Ads and
                  transform your patient acquisition strategies with Medical
                  Sarthi today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <InstaGal />
      </div>
      <div>
        <ContLog />
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <Corect />
      </div>
    </div>
  );
};

export default Patient;
