import React from "react";

const Corect = () => {
  return (
    <section className="text-center pt-2 bottomfooter">
    <h6>
     
      Copyright © Brandologie Marketing Pvt. Ltd. 2024 | All Right Reserved.
    </h6>
    </section>
  );
};

export default Corect;
