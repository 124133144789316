import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import banner2 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { SiGoogleads } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { SiMarketo } from "react-icons/si";
import { GiTicket } from "react-icons/gi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdAddLocation } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { LiaPagerSolid } from "react-icons/lia";

const Media = () => {
  return (
    <div>
      <div>
        <div>
          <Header />
        </div>
        <div className="pt1">
          <div className="container">
            <div className="row">
              <div className="col">
                <button className="pt2">
                  <Link to="/solutions" className="sols">
                    SOLUTIONS
                  </Link>
                </button>
                <h1>Patient Lead Generation</h1>
                <p>
                  Looking to generate more patient leads?
                  <br />
                  Medical Sarthi is here to help! Drive targeted traffic
                  <br />
                  to your clinic with our innovative solution
                </p>
                <button className="pt3">
                  <Link to="/contact" className="frr">
                    Schedule Free Consultation
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h3>Patient Lead Generation Via Google Ads & Meta Ads</h3>
                <p>
                  At Medical Sarthi, we have mastered the art of generating daily
                  10-12 patient enquiries
                  <br />
                  through strategic utilization of Google Ads and Facebook
                  Ads,employing various campaign
                  <br />
                  types and ad formats. Here’s a breakdown of our approach:
                </p>
                <div className="coc">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <h4>
                          <IoCall /> Call-Only Campaigns
                        </h4>
                        <p>
                          We create targeted call-only campaigns that
                          prominently feature your contact number, making it
                          effortless for potential patients to reach out to your
                          practice directly with a simple click-to-call option.
                        </p>
                      </div>
                      <div className="col">
                        <h4>
                          <MdAddLocation /> Location Campaigns
                        </h4>
                        <p>
                          By leveraging location-based targeting, we ensure that
                          your ads are displayed to individuals within specific
                          geographic areas, optimizing ad relevance and
                          increasing the chances of attracting local patients
                          who are actively seeking healthcare services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vac">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div>
                          <h4>
                            <FaVideo /> Video Ads Campaigns
                          </h4>
                          <p>
                            Engaging and visually compelling, video ads offer an
                            impactful way to convey your healthcare services.
                            Through captivating storytelling and informative
                            visuals, we capture the attention of your target
                            audience and inspire them to take action, generating
                            a steady stream of patient enquiries.
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <h4>
                          <LiaPagerSolid /> Landing Page Campaigns
                        </h4>
                        <p>
                          Our strategically designed landing pages serve as
                          dedicated destinations for your ads. These pages are
                          optimized to provide relevant and valuable information
                          to potential patients, encouraging them to take the
                          desired action.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <img className="dd" src={banner2} />
              </div>
            </div>
          </div>
        </div>

        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <img src={banner} />
              </div>

              <div className="col-8">
                <h3 className="ttms">
                  Tailored Marketing Strategies To Reach Wider
                </h3>
                <div className="ttp">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <h5>
                          <FaWhatsapp /> WhatsApp Marketing
                        </h5>
                        <p>
                          We leverage WhatsApp, a widely popular messaging
                          platform, to establish direct communication channels
                          with potential patients. Through targeted campaigns,
                          we send personalized messages, appointment reminders,
                          health tips, and updates, fostering a more
                          personalized and interactive experience. 
                        </p>
                      </div>
                      <div className="col-6">
                        <h5>
                          <MdOutlineAlternateEmail />
                          Email Marketing
                        </h5>
                        <p>
                          While WhatsApp Marketing focuses on instant messaging,
                          email marketing utilizes email as the primary
                          communication channel. Email campaigns involve sending
                          tailored messages, newsletters, promotions, and
                          healthcare-related content directly to potential
                          patients' email inboxes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="pt6">
          <div className="container">
            <div className="row">
              <div className="col-7 ">
                <div className="ppat">
                  <br />
                  <h2>
                    Powerful Platforms and
                    <br /> Tools
                  </h2>
                  <p>
                    Unlock the potential of patient lead generation with our
                    powerful arsenal of platforms and tools. By strategically
                    leveraging cutting-edge technologies and proven techniques,
                    we drive exceptional results, connecting healthcare
                    providers with the right patients and propelling practices
                    to new heights of success.
                  </p>
                </div>
                <div className="scnt">
                  <h4>
                    <SiMarketo />
                    Influencer Marketing
                  </h4>
                  <p>
                    Our strategic influencer marketing campaigns leverage the
                    reach and credibility of influential figures in the
                    healthcare field to promote your practice and services. By
                    partnering with trusted voices, we establish a strong
                    connection with your target audience, driving brand
                    awareness and generating valuable patient leads. Discover
                    the power of influencer marketing and revolutionize your
                    patient acquisition strategies with Medical Sarthi today.
                  </p>
                  <h4>
                    <GiTicket />
                    High Ticket Funnels
                  </h4>
                  <p>
                    Our expertly crafted funnels are designed to attract and
                    nurture qualified leads, driving conversions for high-value
                    healthcare services. With strategic optimization, compelling
                    content, and targeted campaigns, we guide potential patients
                    through a seamless journey, maximizing engagement and
                    generating premium leads. Experience the effectiveness of
                    high-ticket funnels and revolutionize your patient
                    acquisition strategies with Medical Sarthi today.
                  </p>
                </div>
              </div>
              <div className="col-5">
                <div className="scnt">
                  <h4>
                    <SiGoogleads />
                    Google Ads
                  </h4>
                  <p>
                    Our targeted campaigns ensure your healthcare services are
                    visible to potential patients actively searching for
                    relevant solutions, driving high-quality leads. With our
                    expertise, we go beyond generic approaches, tailoring
                    strategies to your unique practice, audience, and goals,
                    ensuring maximum effectiveness. Experience the difference of
                    our data-driven approach and see how Google Ads can
                    revolutionize your patient acquisition efforts.
                  </p>
                  <h4>
                    <FaFacebook />
                    Facebook Ads
                  </h4>
                  <p>
                    Our expertly crafted campaigns precisely target and engage
                    specific demographics, capturing the attention of potential
                    patients where they spend the most time online. With
                    compelling ad creatives and strategic audience targeting, we
                    generate a consistent flow of high-quality leads, helping
                    your healthcare practice thrive in the digital realm.
                    Discover the power of Facebook Ads and revolutionize your
                    patient acquisition strategies today.
                  </p>
                  <h4>
                    <FaYoutube />
                    Youtube Ads
                  </h4>
                  <p>
                    Our captivating video campaigns on the world's largest
                    video-sharing platform connect your healthcare practice with
                    a vast audience. Engage potential patients through visually
                    compelling storytelling, drive brand awareness, and generate
                    a steady stream of high-quality leads. Discover the impact
                    of YouTube Ads and revolutionize your patient acquisition
                    strategies with Medical Sarthi today.
                  </p>
                  <h4>
                    <FaWhatsapp />
                    WhatsApp Ads
                  </h4>
                  <p>
                    Our personalized messaging campaigns on this popular
                    communication platform enable direct and instant
                    communication with potential patients. Engage, educate, and
                    convert with tailored messages, appointment reminders, and
                    timely updates, fostering meaningful connections and driving
                    a continuous flow of quality leads. Experience the
                    effectiveness of WhatsApp Ads and revolutionize your patient
                    acquisition strategies with Medical Sarthi today.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <InstaGal />
        </div>
        <div>
          <ContLog />
        </div>
        <div>
          <Footer />
        </div>
        <div>
          <Corect />
        </div>
      </div>
    </div>
  );
};

export default Media;
