import React from "react";
import Banner from "../../assets/banner6.png"; 
import Banner1 from "../../assets/banner7.jpeg"; 
import Banner2 from "../../assets/banner8.jpeg"; 

const Card = ({ title, imageUrl }) => {
  return (
    <div className="card">
      <img src={imageUrl} alt={title} className="card-img" />
    </div>
  );
};

function ImageSection() {
  const cardsData = [
    {
      imageUrl: Banner, // Use the local image
    },
    {
      imageUrl: Banner1, // Use the local image
    },
    {
      imageUrl: Banner2, // Use the local image
    },
  ];

  // return (
  //   <div className="card-container">
  //     {cardsData.map((card, index) => (
  //       <Card key={index} imageUrl={card.imageUrl} />
  //     ))}
  //   </div>
  // );
}

export default ImageSection;
