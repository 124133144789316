import React from "react";
import banner from "../../assets/background.png";
import banner1 from "../../assets/background3rd.png";
import banner2 from "../../assets/background.png";
import Sliders from "../Sliders";

const Textimg = () => {
  return (
    <section>
      <div className="carousel1">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={2}
              aria-label="Slide 3"
            />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={banner} className="d-block w-100" alt="..." />
              <div className="carousel-caption ">
                <h5>The Begining</h5>
                <p>
                  Medical Sarthi Started Operations On 12 Jan 2020 With A Vision
                  To Provide Creative Marketing Solutions Specifically For
                  Healthcare Industry
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={banner1} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5>Mission</h5>
                <p>
                  Our mission is to transform healthcare marketing by achieving
                  exceptional performance, equipping brands to succeed in the
                  digital space and and establish a lasting presence.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={banner2} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5> We are Global</h5>
                <p>
                  We Have Grown To India’s Leading Healthcare Marketing Agency
                  With 125+ Partner Doctors, Clinics, Hospital & Healthcare
                  Brands Worldwide
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div>
        <div className="Harsh">
          <div className="container">
            <div className="row">
              <div className="col">
                <button>Why Us</button>
                <p>
                  Selecting Medical Sarthi means collaborating with a premier
                  healthcare marketing agency that possesses extensive expertise
                  and a solid history of achievements. Our profound knowledge of
                  the healthcare sector, innovative strategies, and data-centric
                  methods enable us to deliver measurable outcomes for our
                  clients. Our committed team of marketing professionals works
                  closely with each client to customize strategies that address
                  their specific objectives and challenges. We provide a wide
                  range of services, including patient lead generation, online
                  reputation management, website development, and performance
                  marketing, all aimed at enhancing your brand’s visibility,
                  attracting more patients, and boosting revenue. With Medical
                  Sarthi, you can be confident that your marketing initiatives
                  are managed by industry experts dedicated to the success of
                  healthcare brands
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Harsh1">
        <div className="container">
          <div className="row">
            <div className="col">
              <button>Our Core Value</button>
              <p>
                Our culture is built around four key attributes that
                differentiate us from our competition.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Harsh2">
        <div className="container">
          <div className="row">
            <div className="col">
              <h4>Passionate</h4>
              <p>
                We are intensely enthusiastic about providing a superior
                experience.
              </p>
            </div>
            <div className="col">
              <h4>Respectful</h4>
              <p>
                We are polite and kind to one another, even when it gets tough.
              </p>
            </div>
            <div className="col">
              <h4>Ownership</h4>
              <p>
                We are empowered to do our jobs and work towards a common goal.
              </p>
            </div>
            <div className="col">
              <h4>Unified</h4>
              <p>
                We work as a team and trust each other to create a seamless
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Harsh3">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>A team built on experience</h1>
              <p>
                We’re continuing to build our network nationally, bringing
                together a vibrant team with best-in-class service and technical
                skills alike. With more than 800 employees across the US, our
                team is growing fast.
                <br />
                <br />
                {/* <span>Meet the team</span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Harsh4">
        <Sliders />
      </div>
    </section>
  );
};

export default Textimg;
