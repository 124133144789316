import React from "react";
import Header from "../../Component/Header";
import { Link } from "react-router-dom";
import banner from "../../assets/sidebnr.jpg";
import banner1 from "../../assets/newsocial.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Corect from "../../Component/Corect";

const Social = () => {
  return (
    <div>
      <div>
        <div>
          <Header />
        </div>
        <div className="pt1">
          <div className="container">
            <div className="row">
              <div className="col">
                <button className="pt2">
                  <Link to="/solutions" className="sols">
                    SOLUTIONS
                  </Link>
                </button>
                <h1>Social Media Management</h1>
                <p>
                  Enhance your social media presence with Medical Sarthi's
                  expert management services. We specialize in healthcare
                  marketing, utilizing social media platforms to engage your
                  audience, enhance your brand, and drive patient acquisition.
                </p>
                <button className="pt3">
                  <Link to="/contact" className="frr">
                    Schedule a complimentary consultation
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <h5>
                  Enhance your brand with Medical Sarthi's social media
                  management services
                </h5>
                <p>
                  Medical Sarthi distinguishes itself in social media management
                  by providing a customized and results-oriented approach. We
                  recognize the distinct challenges and opportunities within the
                  healthcare industry, enabling us to develop strategies that
                  align with your target audience. Our team integrates
                  creativity, industry expertise, and data-driven insights to
                  develop compelling social media campaigns that enhance
                  engagement and facilitate patient acquisition. </p>
                <div className="coc">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <h4>Facebook & Instagram Management</h4>
                        <p>
                          Our team utilizes the potential of these platforms to
                          connect with your target audience, increase brand
                          awareness, and promote patient acquisition. From
                          strategic content creation to audience targeting and
                          performance tracking.
                        </p>
                      </div>
                      <div className="col">
                        <h4>Content Planning</h4>
                        <p>
                          We recognize the significance of developing engaging
                          and informative content that connects with your target
                          audience. Our team performs thorough research and
                          creates a customized content strategy that aligns with
                          the goals and values of your brand.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vac">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div>
                          <h4>YouTube Management</h4>
                          <p>
                            Our team focuses on utilizing the reach and
                            engagement of this widely-used video-sharing
                            platform to enhance your brand presence, increase
                            audience interaction, and attract new patients. 
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <h4>Content Distribution</h4>
                        <p>
                          We implement targeted strategies to ensure your
                          content effectively reaches the appropriate audience
                          through various channels, including social media
                          platforms, email marketing, influencer collaborations,
                          and more.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <img className="dd" src={banner1} />
              </div>
            </div>
          </div>
        </div>

        <div className="pt5">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <img src={banner} />
              </div>

              <div className="col-8">
                <h3 className="ttms">
                  Medical Sarthi's unique approach to healthcare media marketing
                </h3>
                <div className="ttp">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <h4>Advanced Content Calendar</h4>
                        <p>
                          Our carefully structured and organized approach
                          ensures a consistent and strategic content flow,
                          enabling you to outpace the competition, engage your
                          audience, and achieve significant outcomes for your
                          healthcare brand.
                        </p>
                      </div>
                      <div className="col-6">
                        <h4>Deep Data Insights Tracking</h4>
                        <p>
                          Our sophisticated analytics tools and methodologies
                          offer a comprehensive understanding of your audience,
                          campaign performance, and user behavior, facilitating
                          data-driven decision-making and optimization for
                          maximum impact and ROI.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="pt6">
          <div className="container">
            <div className="row">
              <div className="col-7 ">
                <div className="ppat">
                  <br />
                  <br />
                  <h4>Automated Content Creation</h4>
                  <p>
                    Our cutting-edge technologies and systems harness artificial
                    intelligence and machine learning to produce high-quality,
                    personalized content at scale. Experience the efficiency and
                    effectiveness of automated content creation, conserving time
                    and resources while ensuring a consistent and engaging
                    online presence for your healthcare brand.
                  </p>
                </div>
                {/* <div className="scnt">
                  <h4>Dedicated Media Manager</h4>
                  <p>
                    Our experienced professionals will handle all aspects of
                    your media strategy, including planning, execution,
                    optimization, and performance tracking.
                  </p>
                  <h4>Automations via Tools</h4>
                  <p>
                    From automated scheduling and reporting to personalized
                    messaging and audience segmentation, experience the
                    transformative impact of automation in maximizing your
                    marketing performance and achieving superior results
                    effortlessly.
                  </p>
                </div> */}
              </div>
              <div className="col-5">
                <div className="scnt">
                  <h4>Medical Media Domain Experts</h4>
                  <p>
                    With our comprehensive knowledge of the industry, we
                    recognize the nuances and regulations that govern medical
                    advertising. Our expertise guarantees that your campaigns
                    are compliant, targeted, and effective in engaging your
                    desired audience.
                  </p>
                  <h4>
                    Medical Sarthi's unique approach to healthcare media
                    marketing
                  </h4>
                  <p>
                    Distinguishes itself with its innovative and customized
                    approach to healthcare marketing. We transcend conventional
                    strategies by utilizing data-driven insights and innovative
                    thinking to achieve outstanding results and enhance patient
                    acquisition for our clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <InstaGal />
        </div>
        <div>
          <ContLog />
        </div>
        <div>
          <Footer />
        </div>
        <div>
          <Corect />
        </div>
      </div>
    </div>
  );
};

export default Social;
